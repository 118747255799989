import React, { useEffect, useState } from 'react'
import Pagination from '@mui/material/Pagination'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { translateStatus, statusIconClass } from '../utils'
import { formatMoneyWithCurrency } from '../../../utils/money'

export default function PaymentsList(props) {
  const {
    fetchPayments,
    payments = {},
    paymentsToApprove,
    handlePayAll,
    handleAddPayment,
    cancelPayment,
  } = props

  const [payablePayments, setPayablePayments] = useState([])

  useEffect(() => {
    const payable = []
    payments.records?.forEach((p) => {
      if (p.status === 'waiting_for_approval' || p.status === 'authorization_pending') {
        payable.push(p.id)
      }
    })
    setPayablePayments(payable)
  }, [payments])

  const handlePageClick = (_, page) => {
    fetchPayments(page)
  };

  const handleCancelPayment = (paymentId) => {
    if (window.confirm("Är du säker på att du vill avbryta betalningen?")) {
      cancelPayment(paymentId)
    }
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-3 shadow-lg rounded bg-white pb-2">
      <div className="block w-full overflow-x-auto">
        <table className="payments-table items-center w-full bg-transparent border-collapse rounded-md overflow-hidden">
          <thead className="bg-sky-500">
            <tr>
              <th className="px-3 pl-4 align-middle border border-solid py-1 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
                <div className="flex flex-wrap">
                  <div className="relative w-full lg:w-3/12 mb-5">
                    <FormControlLabel
                      sx={{padding: '20px 0 0 10px', height: '12px'}}
                      control={
                        <Checkbox
                          sx={{
                            color: '#FFFFFF',
                            '&.Mui-checked': {
                              color: '#FFFFFF',
                            },
                          }}
                          disabled={payablePayments.length === 0}
                          inputProps={{ 'aria-label': 'controlled' }}
                          checked={payablePayments.length > 0 && payablePayments.length === paymentsToApprove.length}
                          onChange={(e) => handlePayAll(e.target.checked)}
                        />
                      }
                    />
                  </div>
                </div>
              </th>
              <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
                Löpnr
              </th>
              <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
                {payments.records?.some(p => p.supplier_name) &&
                  "Leverantör"
                }
              </th>
              <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
                {payments.records?.some(p => p.invoice_number) &&
                  "Fakturanr"
                }
              </th>
              <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
                {payments.records?.some(p => p.amount > 0) &&
                  "Summa"
                }
              </th>
              <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
                {payments.records?.some(p => p.due_date) &&
                  "Förfallodatum"
                }
              </th>
              <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
                Status
              </th>
              <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
                Faktura
              </th>
              <th className="px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
                Avbryt
              </th>
            </tr>
          </thead>
          <tbody>
            {payments.records?.map((payment, index) => (
              <tr key={payment.id} style={{background: index % 2 === 0 ? '#f1f1f1' : '#fff'}}>
                <td className="pl-4">
                  <FormControlLabel
                    sx={{margin: 0, padding: 0}}
                    control={
                      <Checkbox
                        disabled={!["waiting_for_approval", "authorization_pending"].includes(payment.status)}
                        sx={{
                          color: '#000000',
                          '&.Mui-checked': {
                            color: '#000000',
                          },
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={paymentsToApprove.some(id => id === payment.id)}
                        onChange={(e) => handleAddPayment(payment.id, e.target.checked)}
                      />
                    }
                  />
                </td>
                <td className="font-bold text-slate-600 border-t-0 px-3 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
                  {payment.payment_number}
                </td>
                <td className="font-bold text-slate-600 border-t-0 px-3 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
                  {payment.supplier_name &&
                    payment.supplier_name
                  }
                </td>
                <td className="font-bold text-slate-600 border-t-0 px-3 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
                  {payment.invoice_number &&
                    payment.invoice_number
                  }
                </td>
                <td className="font-bold text-slate-600 border-t-0 px-3 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
                  {payment.amount > 0 &&
                    formatMoneyWithCurrency(payment.amount, 'SEK', false)
                  }
                </td>
                <td className="font-bold text-slate-600 border-t-0 px-3 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
                  {payment.due_date &&
                    payment.due_date
                  }
                </td>
                <td className="font-bold text-slate-600 border-t-0 px-3 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
                  <i className={"fas fa-circle mr-2 " + statusIconClass(payment.status)} /> {translateStatus(payment.status)}
                </td>
                <td className="font-bold text-slate-600 border-t-0 px-3 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                  {(payment.attachments || []).map((attachment, i) => (
                    <button
                      key={i}
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      onClick={() => window.open(attachment.url)}
                    >
                      Öppna
                      <i className="fas fa-external-link-alt text-white ml-2"/>
                    </button>
                  ))}
                </td>
                <td className="font-bold text-slate-600 border-t-0 px-3 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-2">
                  {payment.cancellable &&
                    <button
                      className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      onClick={() => handleCancelPayment(payment.id)}
                    >
                      Avbryt
                    </button>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="block w-full overflow-x-auto pt-3 px-1 py-1">
          {payments?.meta?.total_pages > 0 &&
            <Pagination
              count={payments.meta.total_pages || 1}
              onChange={handlePageClick}
            />

          }
        </div>
      </div>
    </div>
  )
}
