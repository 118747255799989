import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import FormControl from '@mui/material/FormControl'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import StyledTextField from '../../../components/StyledTextField'
import ClearIcon from '@mui/icons-material/Clear'
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Loader from '../../../components/Loader'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30vw',
  height: '72vh',
  overflowX: 'scroll',
  bgcolor: 'background.paper',
  border: '1px solid #ccc',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

export default function Email(props) {
  const { open, close, onSave, invoiceEmail } = props;

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [email, setEmail] = useState('')
  const [ccs, setCcs] = useState([])
  const [isApiError, setIsApiError] = useState(false)

  useEffect(() => {
    if (invoiceEmail) {
      setEmail(invoiceEmail)
    }
  }, [invoiceEmail])

  const updateCC = (value, index) => {
    const prev = [...ccs]
    prev[index] = value
    setCcs(prev)
  }

  const removeCC = (index) => {
    const prev = [...ccs]
    const updated = prev.filter((_, i) => i !== index);
    setCcs(updated)
  }

  const onSubmit = async (e) => {
    if (!email) {
      setErrors('email')
      return
    }

    setLoading(true)
    const response = await onSave(e, email, ccs)
    if (response.type === 'invoices/sendInvoice/rejected') {
      setIsApiError(true)
    } else {
      setIsApiError(false)
    }
    setLoading(false)
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{backgroundColor: 'none'}}
    >
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10} lg={10}>
            <h1 className="block uppercase text-slate-600 text-sm font-bold mb-2">Skicka faktura</h1>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <ClearIcon
              onClick={close}
              sx={{
                float: 'right',
                margin: '-20px -20px 0',
                cursor: 'pointer'
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <StyledTextField
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size='small'
                fontSize='13px'
                placeholder="E-postadress"
                error={errors.includes('email')}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
          {
            Array.from({ length: ccs.length + 1 }, (_, i) => (
              <Grid container key={i}>
                <Grid item xs={11}>
                  <FormControl fullWidth key={i}>
                    <StyledTextField
                      value={ccs[i]}
                      onChange={(e) => updateCC(e.target.value, i)}
                      size='small'
                      fontSize='13px'
                      placeholder="Lägg till CC"
                    />
                  </FormControl>
                </Grid>
                {!!ccs[i] &&
                  <>
                    <Grid item xs={1}>
                      <DisabledByDefaultIcon
                        className="text-red-500"
                        style={{cursor: 'pointer', margin: '0 0 0 -15px', fontSize: '36px'}}
                        onClick={() => removeCC(i)}
                      />
                    </Grid>
                  </>
                }
              </Grid>
            ))
          }
          </Grid>
          <Grid item xs={12}>
            <button
              onClick={(e) => onSubmit(e)}
              style={{minWidth: '112px', height: '56px'}}
              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              { loading ?
                <Loader
                  size={25}
                  thickness={5}
                  style={{
                    margin: 0,
                    padding: 0,
                    color: '#ffffff'
                  }}
                />
               :
                'Skicka'
              }
            </button>
          </Grid>
          {isApiError &&
            <Grid item xs={12}>
              <p>Något gick fel, försök igen eller kontakta support på info@ekonomimolnet.se</p>
            </Grid>
          }
        </Grid>
      </Box>
    </Modal>
  )
}
