import React from 'react'

export default function Filter(props) {
  const {
    statuses,
    filterOnStatus,
  } = props

  return (
    <div className="flex flex-wrap">
      <div className="w-full">
        <ul
          className="flex mb-0 list-none flex-wrap pt-2 pb-2 flex-row"
          role="tablist"
        >
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <button
              className={
                "w-full cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                ((statuses.includes('waiting_for_approval') && statuses.includes('authorization_pending')
                  ? "text-white bg-sky-700"
                  : "text-sky-700 bg-white"))
              }
              onClick={() => filterOnStatus(['waiting_for_approval', 'authorization_pending']) }
              role="tablist"
            >
              Att hantera
            </button>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <button
              className={
                "w-full cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (statuses.length === 0
                  ? "text-white bg-sky-700"
                  : "text-sky-700 bg-white")
              }
              onClick={() => filterOnStatus([]) }
              role="tablist"
            >
              Alla
            </button>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <button
              className={
                "w-full cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                ((statuses.includes('unprocessed') &&  statuses.includes('supplier_invoice_created')
                  ? "text-white bg-sky-700"
                  : "text-sky-700 bg-white"))
              }
              onClick={() => filterOnStatus(['unprocessed', 'supplier_invoice_created']) }
              role="tablist"
            >
              Under behandling
            </button>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <button
              className={
                "w-full cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (statuses.includes('approved')
                  ? "text-white bg-sky-700"
                  : "text-sky-700 bg-white")
              }
              onClick={() => filterOnStatus(['approved']) }
              role="tablist"
            >
              Signerade
            </button>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <button
              className={
                "w-full cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (statuses.includes('done')
                  ? "text-white bg-sky-700"
                  : "text-sky-700 bg-white")
              }
              onClick={() => filterOnStatus(['done']) }
              role="tablist"
            >
              Betalade
            </button>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <button
              className={
                "w-full cursor-pointer text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                ((statuses.includes('rejected') && statuses.includes('failed') && statuses.includes('unknown')
                  ? "text-white bg-sky-700"
                  : "text-sky-700 bg-white"))
              }
              onClick={() => filterOnStatus(['rejected', 'failed', 'unknown']) }
              role="tablist"
            >
              Misslyckade
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}
