import React, { useState, useEffect } from 'react'
import i18n from '../../../i18n'
import Collapse from '@mui/material/Collapse';
import Loader from '../../../components/Loader'
import Pagination from '@mui/material/Pagination';
import SettingsIcon from '@mui/icons-material/Settings';
import InvoiceActionsDropdown from './InvoiceActionsDropdown'
import Rows from './Rows'
import { formatMoneyWithCurrency } from '../../../utils/money'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Email from './Email'

export default function List(props) {
  const {
    editedInvoiceId,
    invoices,
    fetchInvoices,
    loadingData,
    meta,
  } = props
  const t = i18n.getFixedT()

  const changePage = (_, page) => {
    fetchInvoices(page)
  };

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-3 shadow-lg rounded bg-white pb-2">
      <div className="block w-full overflow-x-auto">
      <table className="invoices-table items-center w-full bg-transparent border-collapse rounded-md overflow-hidden">
        <thead className="bg-sky-500">
          <tr>
            <th className="px-4 pl-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
              {t('customer')}
            </th>
            <th className="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
              {t('invoiceNr')}
            </th>
            <th className="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
              {t('invoiceDate')}
            </th>
            <th className="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
              {t('dueDate')}
            </th>
            <th className="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
              {t('status')}
            </th>
            <th className="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
              {t('amount')}
            </th>
            <th className="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
              {t('vat')}
            </th>
            <th className="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
              {t('sent')}
            </th>
            <th className="px-4 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left text-white">
              <SettingsIcon sx={{color: '#FFFFFF'}}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {loadingData ?
              <tr>
                <td style={{height: '60vh'}}>
                  <Loader
                    style={{
                      position: 'absolute',
                      top: '45%',
                      left: '45%',
                      color: 'rgb(14 165 233)'
                    }}
                    size={100}
                  />
                </td>
              </tr>
            :
          invoices.map((invoice, i) => (
            <Invoice
              {...props}
              index={i}
              invoice={invoice}
              key={i}
              open={editedInvoiceId === invoice.id}
            />
          ))}
          {meta.total_pages > 0 &&
            <tr>
              <td className="w-full p-2 pl-4">
                <Pagination
                  count={meta.total_pages || 1}
                  onChange={changePage}
                />
              </td>
            </tr>
          }
        </tbody>
      </table>
      </div>
    </div>
  )
}

function Invoice(props) {
  const {
    invoice,
    editInvoice,
    creditInvoice,
    copyInvoice,
    sendInvoice,
    printInvoice,
    printInvoiceGrover,
    cancelInvoice,
    open,
    index,
  } = props;
  const [rowsOpen, setRowsOpen] = useState(false)
  const [emailModalOpen, setEmailModalOpen] = useState(false)

  useEffect(() => {
    if (open && !rowsOpen) {
      setRowsOpen(true)
    }
  }, [open, rowsOpen, setRowsOpen])

  const sendEmail = async(e, email, ccs) => {
    const response = await sendInvoice(invoice, email, ccs)
    if (response.type === 'invoices/sendInvoice/fulfilled') {
      setEmailModalOpen(false)
    } else {
      return response
    }
  }

  let textClass = 'text-amber-500'
  if (invoice.paid) {
    textClass = 'text-emerald-500'
  } else if (invoice.cancelled) {
    textClass = 'text-slate-400'
  } else if (invoice.overdue && !invoice.credit && !invoice.credited) {
    textClass = 'text-red-500'
  }

  return (
    <React.Fragment>
      <tr style={{background: index % 2 === 0 ? '#f1f1f1' : '#fff'}}>
        <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3 text-left flex items-center">
          <span className="ml-3 font-bold text-slate-600">
            { rowsOpen ?
              <KeyboardArrowUpIcon
                sx={{cursor: 'pointer', marginRight: '10px'}}
                onClick={() => setRowsOpen(false)}
              />
              :
              <KeyboardArrowDownIcon
                sx={{cursor: 'pointer', marginRight: '10px'}}
                onClick={() => setRowsOpen(true)}
              />
            }
            {invoice.customer.name}
          </span>
        </td>
        <td className="font-bold text-slate-600 border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
          {invoice.invoice_number}
        </td>
        <td className="font-bold text-slate-600 border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
          {invoice.invoice_date}
        </td>
        <td className="font-bold text-slate-600 border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
          {invoice.due_date}
        </td>
        <td className="font-bold text-slate-600 border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
          <i className={"fas fa-circle mr-2 " + textClass}></i>
            { invoice.cancelled ? "Makulerad"
              :
              invoice.paid || invoice.credited || invoice.credit ? "Betald" : "Obetald"
            }
        </td>
        <td className="font-bold text-slate-600 border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
          {formatMoneyWithCurrency(invoice.total, invoice.currency)}
        </td>
        <td className="font-bold text-slate-600 border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
          {formatMoneyWithCurrency(invoice.total_vat, invoice.currency)}
        </td>
        <td className="font-bold text-slate-600 border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
          {invoice.sent ?
            <i className="far fa-check-circle text-emerald-500 text-lg leading-none" ></i>
            :
            <i className="far fa-times-circle text-red-500 text-lg leading-none"></i>
          }
        </td>
        <td className="font-bold text-slate-600 border-t-0 px-4 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-3">
          <InvoiceActionsDropdown
            editInvoice={editInvoice}
            copyInvoice={copyInvoice}
            creditInvoice={creditInvoice}
            sendInvoice={sendInvoice}
            invoice={invoice}
            printInvoice={printInvoice}
            printInvoiceGrover={printInvoiceGrover}
            cancelInvoice={cancelInvoice}
            openEmailModal={() => setEmailModalOpen(true)}
          />
        </td>
      </tr>
      <tr>
        <td style={{ padding: '0' }} colSpan={10}>
          <Collapse in={rowsOpen} timeout="auto" unmountOnExit>
            <Rows
              invoice={invoice}
              rows={invoice.invoice_rows}
            />
          </Collapse>
        </td>
      </tr>
      <Email
        close={() => setEmailModalOpen(false)}
        invoiceEmail={invoice.customer.email}
        onSave={sendEmail}
        open={emailModalOpen}
      />
    </React.Fragment>
  )
}
