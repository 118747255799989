import React from 'react'
import { formatMoneyWithCurrency } from '../../../utils/money'

export default function Rows(props) {
  const { invoice, rows } = props

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full rounded bg-transparent">
      <div className="block w-full overflow-x-auto">
        <table className="w-full">
          <thead className="bg-sky-500 text-white text-sm font-bold">
            <tr>
              <td className="px-4 py-2 pl-14">Beskrivning</td>
              <td className="px-4 py-2 pl-14">Enhet</td>
              <td className="px-4 py-2 pl-14">Antal</td>
              <td className="px-4 py-2 pl-14">À-pris</td>
              <td className="px-4 py-2 pl-14">Rabatt</td>
              <td className="px-4 py-2 pl-14">Summa</td>
            </tr>
          </thead>
          <tbody className="bg-transparent text-xs text-slate-700">
            {rows.map((row, i) => (
              <tr key={i} className="border-b">
                <td className="px-4 py-2 pl-14">{row.description}</td>
                <td className="px-4 py-2 pl-14">{row.unit.description}</td>
                <td className="px-4 py-2 pl-14">{row.quantity}</td>
                <td className="px-4 py-2 pl-14">{formatMoneyWithCurrency(row.amount, invoice.currency || 'SEK')}</td>
                <td className="px-4 py-2 pl-14">{`${row.discount * 100}%`}</td>
                <td className="px-4 py-2 pl-14">{formatMoneyWithCurrency(row.total, invoice.currency || 'SEK')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
