import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import FileUploader from '../../components/FileUploader'
import PaymentsList from './components/paymentsList'
import Filter from './components/filter'
import BankAuthenticationComponent from './components/bankAuthenticationComponent'
import {
  paymentsSlice,
  fetchPaymentsAsync,
  createPaymentFromAttachmentAsync,
  selectPaymentsData,
  selectPaymentsStatus,
  cancelPaymentAsync,
} from './paymentsSlice'
import { selectActiveCompany } from '../../features/companies/activeCompanyIdReducer'

export default function Payments(props) {
  const dispatch = useDispatch()
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [paymentsToApprove, setPaymentsToApprove] = useState([])
  const [bankAuthModalOpen, setBankAuthModalOpen] = useState(false)
  const [statuses, setStatuses] = useState([])
  const [emailCopied, setEmailCopied] = useState(false)

  const activeCompany = useSelector(selectActiveCompany)
  const paymentsData = useSelector(selectPaymentsData)
  const paymentsStatus = useSelector(selectPaymentsStatus)

  useEffect(() => {
    if (paymentsStatus === 'idle') {
      dispatch(fetchPaymentsAsync({page: 1, statuses: []}))
      setStatuses([])
    }
  }, [paymentsStatus, activeCompany, statuses, dispatch])

  const handlePayAll = (add) => {
    const paymentIds = []
    if (add) {
      paymentsData.records.forEach((p) => {
        if (p.status === 'waiting_for_approval') {
          paymentIds.push(p.id)
        }
      })
      setPaymentsToApprove(paymentIds)
    } else {
      setPaymentsToApprove([])
    }
  }

  const handleAddPayment = (paymentId, add) => {
    const currPayments = [...paymentsToApprove]
    if (add) {
      currPayments.push(paymentId)
    } else {
      const index = currPayments.findIndex(id => id === paymentId);
      if (index > -1) {
        currPayments.splice(index, 1)
      }
    }

    setPaymentsToApprove(currPayments)
  }

  const copyEmail = async() => {
    setEmailCopied(true)
    await navigator.clipboard.writeText(activeCompany?.inbound_email)
  }

  const handleAuthError = () => {
    setPaymentsToApprove([])
    dispatch(fetchPaymentsAsync({page: 1, statuses: statuses}))
  }

  const confirmPayments = (payments) => {
    setPaymentsToApprove([])
    dispatch(paymentsSlice.actions.confirmPayments(payments))
  }

  const fetchPayments = (page) => {
    dispatch(fetchPaymentsAsync({page: page, statuses: statuses}))
  }

  const cancelPayment = (paymentId) => {
    dispatch(cancelPaymentAsync(paymentId))
  }

  const onUpload = async(attachments, fileName) => {
    const formData = new FormData();
    attachments.forEach((attachment) => {
      formData.append('files[]', attachment.file);
    });

    const response = await dispatch(createPaymentFromAttachmentAsync(formData))

    if (response.type === 'payments/createPaymentFromAttachmentAsync/fulfilled') {
      setUploadModalOpen(false)
      setStatuses(['unprocessed', 'supplier_invoice_created'])
      dispatch(fetchPaymentsAsync({page: 1, statuses: ['unprocessed', 'supplier_invoice_created']}))
    }
  }

  const filterOnStatus = (statuses) => {
    setStatuses(statuses)
    dispatch(fetchPaymentsAsync({page: 1, statuses: statuses}))
  }

  return (
    <div className="relative md:pt-32 pb-32 pt-12">
      <div className="flex flex-wrap px-4">
        {activeCompany?.payment_account_id &&
          <div className="w-full lg:w-3/12 lg:pr-1 pt-1">
            <button
              disabled={paymentsToApprove.length === 0}
              onClick={() => setBankAuthModalOpen(true)}
              className={
                      "w-full bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 " +
                      (paymentsToApprove.length === 0
                        ? "bg-slate-200"
                        : "bg-emerald-500")
                    }
            >
              Godkänn betalningar
            </button>
          </div>
        }
        <div className="w-full lg:w-9/12">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-4/12 lg:pr-1 pt-1">
              <button
                onClick={() => setUploadModalOpen(true)}
                className="w-full bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              >
                Ladda upp leverantörsfaktura
              </button>
            </div>
            {activeCompany?.inbound_email &&
              <div className="w-full lg:w-6/12 lg:pr-1 pt-1">
                <a
                  className="text-center w-full block bg-transparent text-slate-700 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  style={{height: '48px'}}
                >
                  <i className="far fa-envelope pr-1 m-0"/>
                  E-posta underlag:
                  <span
                    className={"ml-1 " + (emailCopied ? "fade" : "")}
                    onAnimationEnd={() => setEmailCopied(false)}
                  >
                    {activeCompany?.inbound_email}
                  </span>
                  <i
                    className="cursor-pointer fas fa-copy pl-2 text-xl text-emerald-500 active:text-emerald-200 ease-linear duration-150"
                    onClick={() => copyEmail()}
                  />
                </a>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="flex flex-wrap px-4 pt-1">
        <div className="w-full">
          {activeCompany?.payment_account_id &&
            <Filter
              statuses={statuses}
              filterOnStatus={filterOnStatus}
            />
          }
        </div>
      </div>
      <div className="flex flex-wrap px-4 pt-1">
        <div className="w-full">
          <PaymentsList
            fetchPayments={fetchPayments}
            payments={paymentsData}
            paymentsToApprove={paymentsToApprove}
            handleAddPayment={handleAddPayment}
            handlePayAll={handlePayAll}
            cancelPayment={cancelPayment}
          />
        </div>
      </div>

      <FileUploader
        title="Ladda upp underlag för betalning"
        onClose={() => setUploadModalOpen(false)}
        onUpload={onUpload}
        open={uploadModalOpen}
        multiple={true}
      />
      <BankAuthenticationComponent
        open={bankAuthModalOpen}
        onError={handleAuthError}
        onSuccess={confirmPayments}
        onClose={() => setBankAuthModalOpen(false)}
        paymentsToApprove={paymentsData.records?.filter(p => paymentsToApprove.some(id => id === p.id))}
      />
    </div>
  )
}
