import React, { useState } from 'react'
import dayjs from 'dayjs';
import Select, { components } from 'react-select'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomerForm from './CustomerForm'
import EditIcon from '@mui/icons-material/Edit';
import {
  invoiceSelectLarge,
  invoiceSelectSmall,
} from './SelectStyles';

export default function SharedFields(props) {
  const [editCustomer, setEditCustomer] = useState(false)

  const {
    activeCompany,
    customerData,
    customerOptions,
    searchCustomers,
    selectCustomer,
    customer,
    updateInvoiceDate,
    invoiceDate,
    updateDueDate,
    dueDate,
    termsOfPaymentOptions,
    updateTermsOfPayment,
    termsOfPayment,
    ourReference,
    updateOurReference,
    yourReference,
    updateYourReference,
    createCustomer,
    updateCustomer,
    customerFormOpen,
    toggleCustomerForm,
    invoiceErrors,
  } = props;

  const onEditCustomer = () => {
    setEditCustomer(true)
    toggleCustomerForm()
  }

  const onCreateCustomer = () => {
    setEditCustomer(false)
    toggleCustomerForm()
  }

  const customerOption = ({ value, label }) => (
    <div>
      {label}
      <EditIcon
        onClick={() => onEditCustomer()}
        style={{
          cursor: 'pointer',
          fontSize: '20px',
          float: 'right',
          margin: '0 5px 0 0',
        }}
      />
    </div>
  )

  const SingleValue = ({ ...props }) => {
    return (
      <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
    )
  }

  return (
    <>
      <div className="flex flex-wrap px-4">
        <div className="w-full lg:w-3/12 px-1">
          <div className="relative w-full mb-3">
            <div className="flex flex-wrap pr-1">
              <div className="relative w-full lg:w-11/12 mb-3">
                <label
                  className="block uppercase text-slate-600 text-xs font-bold mb-2"
                >
                  Kund
                </label>
                <Select
                  components={{ SingleValue }}
                  placeholder=""
                  options={customerOptions}
                  onKeyDown={(e) => searchCustomers(e.target.value)}
                  onChange={(value) => selectCustomer(value)}
                  formatOptionLabel={customerOption}
                  value={customer}
                  styles={invoiceSelectLarge(invoiceErrors.includes('customer_id'))}
                />
              </div>
              <div className="relative w-full lg:w-1/12 lg:mt-7 pl-1">
                <i
                  className="cursor-pointer fas fa-plus-square text-emerald-500 text-3xl"
                  onClick={() => onCreateCustomer()}
                  style={{fontSize: '37px'}}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-4/12">
          <div className="flex flex-wrap">
            <div className="relative w-full lg:w-4/12 mb-3 px-1 pl-2">
              <label
                className="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Betalvillkor
              </label>
              <Select
                placeholder="Betalningsvillkor"
                options={termsOfPaymentOptions}
                isSearchable={false}
                value={termsOfPayment}
                onChange={(value) => updateTermsOfPayment(value)}
                styles={invoiceSelectSmall(invoiceErrors.includes('termsOfPayment'))}
              />
            </div>
            <div className="relative w-full lg:w-4/12 mb-3 px-1">
              <label
                className="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Fakturadatum
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  onChange={(value) => updateInvoiceDate(value)}
                  value={dayjs(invoiceDate)}
                  format="YYYY-MM-DD"
                  slotProps={{
                    day: {
                      sx: {
                        "&.MuiPickersDay-root.Mui-selected": {
                          color: "#fff",
                          backgroundColor: "rgb(14 165 233)",
                          borderColor: "rgb(14 165 233)",
                          ":hover": {
                            color: "#fff",
                            backgroundColor: "rgb(14 165 233)",
                            borderColor: "rgb(14 165 233)"
                          }
                        },
                        "&.MuiPickersDay-today": {
                          borderColor: "rgb(14 165 233)",
                        },
                      },
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      border: invoiceErrors.includes('invoice_date') ? '1px solid red' : 'none',
                      height: '44px',
                      fontSize: '13px',
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 'none',
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="relative w-full lg:w-4/12 mb-3 px-1">
              <label
                className="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Förfallodatum
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  onChange={(value) => updateDueDate(value)}
                  value={dayjs(dueDate)}
                  format="YYYY-MM-DD"
                  slotProps={{
                    day: {
                      sx: {
                        "&.MuiPickersDay-root.Mui-selected": {
                          color: "#fff",
                          backgroundColor: "rgb(14 165 233)",
                          borderColor: "rgb(14 165 233)",
                          ":hover": {
                            color: "#fff",
                            backgroundColor: "rgb(14 165 233)",
                            borderColor: "rgb(14 165 233)"
                          }
                        },
                        "&.MuiPickersDay-today": {
                          borderColor: "rgb(14 165 233)",
                        },
                      },
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      border: invoiceErrors.includes('due_date') ? '1px solid red' : 'none',
                      height: '44px',
                      fontSize: '13px',
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: 'none'
                    }
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/12 px-1">
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-slate-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Vår referens
            </label>
            <input
              type="text"
              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              value={ourReference}
              onChange={(e) => updateOurReference(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full lg:w-2/12 px-1">
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-slate-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Er referens
            </label>
            <input
              type="text"
              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              value={yourReference}
              onChange={(e) => updateYourReference(e.target.value)}
            />
          </div>
        </div>
      </div>
      <CustomerForm
        activeCompany={activeCompany}
        customer={editCustomer ? customerData : {}}
        close={toggleCustomerForm}
        onSave={editCustomer ? updateCustomer : createCustomer}
        open={customerFormOpen}
      />
    </>
  )
}
