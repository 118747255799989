export function formatMoneyWithCurrency(amount, currency, round) {
  const options = {
    style: 'currency',
    currency: currency || 'SEK',
  }

  if (round) {
    options.minimumFractionDigits = 0
    options.maximumFractionDigits = 0
  }

  const formatter = new Intl.NumberFormat('sv-SE', options)

  return formatter.formatToParts(amount).map(({type, value}) => {
    switch (type) {
      case 'currency': return currency
      default : return value
    }
  }).reduce((string, part) => string + part)
}

export function formatMoney(amount) {
  return new Intl.NumberFormat('sv-SE', { minimumFractionDigits: 2 }).format(amount)
}
