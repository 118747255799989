import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  fetchActiveCompanyAsync,
  selectActiveCompanyData,
  selectActiveCompanyStatus,
  selectCustomersData,
  selectCustomersStatus,
  searchCustomersAsync,
  selectAccountsData,
  searchAccountsAsync,
  selectArticlesData,
  selectArticlesStatus,
  fetchArticlesAsync,
  selectUnitsData,
  selectUnitsStatus,
  selectInvoicesData,
  selectInvoicesStatus,
  fetchUnitsAsync,
  createInvoiceAsync,
  updateInvoiceAsync,
  fetchInvoicesAsync,
  sendInvoiceAsync,
  createArticleAsync,
  createCustomerAsync,
  updateCustomerAsync,
  generateInvoicePDFAsync,
} from './invoicesSlice'
import { selectActiveFinancialYear } from '../financialYears/activeFinancialYearIdReducer'
import Form from './components/Form';
import List from './components/List';

export default function Invoices(props) {
  const dispatch = useDispatch()

  const activeCompanyData = useSelector(selectActiveCompanyData)
  const activeCompanyStatus = useSelector(selectActiveCompanyStatus)
  const activeFinancialYear = useSelector(selectActiveFinancialYear)
  const customersStatus = useSelector(selectCustomersStatus)
  const customersData = useSelector(selectCustomersData)
  const accountsData = useSelector(selectAccountsData)
  const articlesData = useSelector(selectArticlesData)
  const articlesStatus = useSelector(selectArticlesStatus)
  const unitsData = useSelector(selectUnitsData)
  const unitsStatus = useSelector(selectUnitsStatus)
  const invoicesData = useSelector(selectInvoicesData)
  const invoicesStatus = useSelector(selectInvoicesStatus)
  const [customerFormOpen, setCustomerFormOpen] = useState(false)
  const [activeInvoice, setActiveInvoice] = useState(null)
  const [fromInvoiceCopy, setFromInvoiceCopy] = useState(false)
  const [creditedInvoice, setCreditedInvoice] = useState(false)
  const [editedInvoiceId, setEditedInvoiceId] = useState(null)

  useEffect(() => {
    if (activeCompanyStatus === 'idle') {
      dispatch(fetchActiveCompanyAsync())
    }
  }, [activeCompanyStatus, dispatch])

  useEffect(() => {
    if (articlesStatus === 'idle') {
      dispatch(fetchArticlesAsync())
    }
  }, [articlesStatus, dispatch])

  useEffect(() => {
    if (unitsStatus === 'idle') {
      dispatch(fetchUnitsAsync())
    }
  }, [unitsStatus, dispatch])

  useEffect(() => {
    if (invoicesStatus === 'idle') {
      dispatch(fetchInvoicesAsync(1))
    }
  }, [invoicesStatus, dispatch])

  useEffect(() => {
    if (customersStatus === 'idle') {
      dispatch(searchCustomersAsync(''))
    }
  }, [customersStatus, dispatch])

  const createArticle = async(params) => {
    const response = await dispatch(createArticleAsync(params))
    return response.payload
  }

  const createCustomer = async(params) => {
    const response = await dispatch(createCustomerAsync(params))
    setCustomerFormOpen(false)
    return response.payload
  }

  const updateCustomer = async(params) => {
    const response = await dispatch(updateCustomerAsync(params))
    setCustomerFormOpen(false)
    return response.payload
  }

  const searchCustomers = async(name) => {
    // TODO: Do we need debounce?
    dispatch(searchCustomersAsync(name))
  }

  const searchAccounts = async(query) => {
    // TODO: Do we need debounce?
    dispatch(searchAccountsAsync(query))
  }

  const createInvoice = async(params) => {
    const response = await dispatch(createInvoiceAsync(params))
    return response
  }

  const updateInvoice = async(params) => {
    const response = await dispatch(updateInvoiceAsync(params))
    setEditedInvoiceId(params.id)
    return response
  }

  const editInvoice = (invoice) => {
    setActiveInvoice(invoice)
  }

  const copyInvoice = (invoice) => {
    setActiveInvoice(invoice)
    setFromInvoiceCopy(true)
  }

  const creditInvoice = (invoice) => {
    const rows = [...invoice.invoice_rows]
    const creditRows = rows.map((row) => {
      const rowCopy = {...row}
      rowCopy.amount = row.amount * -1
      return rowCopy
    })
    const credited = {...invoice}
    credited.invoice_rows = creditRows
    setActiveInvoice(credited)
    setCreditedInvoice(true)
  }

  const fetchInvoices = (page) => {
    dispatch(fetchInvoicesAsync(page))
  }

  const sendInvoice = async(invoice, email, ccs) => {
    if (!invoice.pdf_url) {
      await dispatch(generateInvoicePDFAsync(invoice.id))
    }
    return dispatch(sendInvoiceAsync({
      invoiceId: invoice.id,
      email: email,
      ccs: ccs,
    }))
  }

  const printInvoice = async(invoice) => {
    if (!invoice.pdf_url) {
      const response = await dispatch(generateInvoicePDFAsync(invoice.id))
      window.open(response.payload.pdf_url)
    } else {
      window.open(invoice.pdf_url)
    }
  }

  const cancelInvoice = (invoice) => {
    dispatch(updateInvoiceAsync({id: invoice.id, cancelled: true}))
  }

  const toggleCustomerForm = () => {
    setCustomerFormOpen(!customerFormOpen)
  }

  return (
    <div className="relative md:pt-32 pb-12 pt-12 px-2">
      <div className="w-full lg:w-12/12 px-2 pl-4">
        <div className="relative w-full mb-3">
          <Form
            activeCompany={activeCompanyData}
            activeFinancialYear={activeFinancialYear}
            accounts={accountsData}
            articles={articlesData}
            createArticle={createArticle}
            createInvoice={createInvoice}
            creditedInvoice={creditedInvoice}
            customers={customersData}
            createCustomer={createCustomer}
            updateCustomer={updateCustomer}
            fromCopy={fromInvoiceCopy}
            invoice={activeInvoice}
            searchAccounts={searchAccounts}
            searchCustomers={searchCustomers}
            units={unitsData}
            updateInvoice={updateInvoice}
            customerFormOpen={customerFormOpen}
            toggleCustomerForm={toggleCustomerForm}
            printInvoice={printInvoice}
            sendInvoice={sendInvoice}
          />
        </div>
      </div>
      <div className="w-full lg:w-12/12 px-2 pl-4">
        <div className="relative w-full mb-3">
          <List
            copyInvoice={copyInvoice}
            editInvoice={editInvoice}
            creditInvoice={creditInvoice}
            printInvoice={printInvoice}
            invoices={invoicesData.records}
            loadingData={invoicesStatus === 'loading'}
            meta={invoicesData.meta}
            editedInvoiceId={editedInvoiceId}
            cancelInvoice={cancelInvoice}
            fetchInvoices={fetchInvoices}
            sendInvoice={sendInvoice}
          />
        </div>
      </div>
    </div>
  );
}
