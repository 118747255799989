import React, { useState, useEffect } from 'react'
import Select from "react-select";
import { formatMoney } from '../../../utils/money'
import {
  invoiceSelectSmall,
  invoiceSelectLarge,
} from './SelectStyles';

export default function InvoiceRow(props) {
  const {
    index,
    row,
    articleOptions,
    removeInvoiceRow,
    updateInvoiceRow,
    unitOptions,
    discountOptions,
    selectArticle,
    createArticle,
    errors,
  } = props;

  const [canAddArticle, setCanAddArticle] = useState(false)
  const [salesAccounts, setSalesAccounts] = useState([])

  useEffect(() => {
    if (row.article.value) {
      return
    }
    if (row.description && row.amount && row.unit?.value && row.vat?.value && row.account?.value) {
      setCanAddArticle(true)
    } else {
      setCanAddArticle(false)
    }
  }, [row, canAddArticle])

  useEffect(() => {
    if (props.salesAccounts) {
      const normalized = props.salesAccounts.map((account) => {
        return {
          value: { number: account.account_number, vat: account.vat },
          label: account.description,
        }
      })
      setSalesAccounts(normalized)
    }
  }, [props.salesAccounts])

  const onUpdate = (field, value) => {
    const updatedRow = row
    updatedRow[field] = value

    updateInvoiceRow(updatedRow, index)
  }

  const onAddArticle = async(e) => {
    e.preventDefault()

    const params = {
      description: row.description,
      sales_price: row.amount,
      vat: row.sales_account?.value?.vat,
      unit_id: row.unit.value,
      sales_account: row.sales_account?.value?.number,
    }

    const response = await createArticle(params)
    const article = {label: response.description, value: response.id}
    onUpdate('article', article)
  }

  const onSelectArticle = (value) => {
    const updatedRow = row
    updatedRow.article = value

    selectArticle(value, updatedRow, index)
  }

  const onRemoveInvoiceRow = (e) => {
    e.preventDefault()
    removeInvoiceRow(index)
  }

  return (
    <div className="flex flex-wrap px-4">
      <div className="w-full lg:w-5/12">
        <div className="flex flex-wrap">
          <div className="relative w-full lg:w-3/12 mb-3 px-1">
            {index === 0 &&
              <label
                className="block uppercase text-slate-600 text-xs font-bold mb-2"
              >
                Artikel
              </label>
            }
            <Select
              placeholder=""
              value={row.article}
              options={articleOptions}
              onChange={(value) => onSelectArticle(value)}
              styles={invoiceSelectLarge(false)}
            />
          </div>
          <div className="relative w-full lg:w-9/12 mb-3 px-1">
            {index === 0 &&
              <label
                className="block uppercase text-slate-600 text-xs font-bold mb-2"
              >
                Beskrivning
              </label>
            }
            <input
              type="text"
              className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              value={row.description}
              onChange={(e) => onUpdate('description', e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="w-full lg:w-6/12">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12">
            <div className="flex flex-wrap">
              <div className="relative w-full lg:w-3/12 mb-3 px-1">
                {index === 0 &&
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                  >
                    Enhet
                  </label>
                }
                <Select
                  placeholder=""
                  value={row.unit}
                  options={unitOptions}
                  isSearchable={false}
                  onChange={(value) => onUpdate('unit', value)}
                  styles={invoiceSelectSmall(errors.includes('unit_id'))}
                />
              </div>
              <div className="relative w-full lg:w-3/12 mb-3 px-1">
                {index === 0 &&
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                  >
                    Antal
                  </label>
                }
                <input
                  type="number"
                  className={
                    "px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 " +
                    (errors.includes('quantity')
                      ? "border-1 border-red-500"
                      : "border-0")

                  }
                  value={row.quantity}
                  onChange={(e) => onUpdate('quantity', e.target.value)}
                />
              </div>
              <div className="relative w-full lg:w-3/12 mb-3 px-1">
                {index === 0 &&
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                  >
                    À-pris
                  </label>
                }
                <input
                  type="number"
                  className={
                    "px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 " +
                    (errors.includes('amount')
                      ? "border-1 border-red-500"
                      : "border-0")
                  }
                  value={row.amount}
                  onChange={(e) => onUpdate('amount', e.target.value)}
                />
              </div>
              <div className="relative w-full lg:w-3/12 mb-3 px-1">
                {index === 0 &&
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                  >
                    Rabatt
                  </label>
                }
                <Select
                  placeholder=""
                  options={discountOptions}
                  value={row.discount}
                  isSearchable={false}
                  onChange={(value) => onUpdate('discount', value)}
                  styles={invoiceSelectSmall(false)} // TODO: Change false to validation error
                />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12">
            <div className="flex flex-wrap">
              <div className="relative w-full lg:w-5/12 mb-3 px-1">
                {index === 0 &&
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                  >
                    Summa
                  </label>
                }
                <input
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={row.amount > 0 && row.quantity > 0 ? formatMoney(row.amount * row.quantity) : ""}
                  disabled={true}
                />
              </div>
              <div className="relative w-full lg:w-7/12 mb-3 px-1">
                {index === 0 &&
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                  >
                    Konto
                  </label>
                }
                <Select
                  options={salesAccounts}
                  value={row.sales_account}
                  isSearchable={false}
                  onChange={(value) => onUpdate('sales_account', value)}
                  styles={invoiceSelectLarge(errors.includes('vat') || errors.includes('account_number'))} // TODO: Change false to validation error
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/12 px-1">
        <div className="relative w-full mb-3">
          <i
            className={"fas fa-trash-alt text-red-500 cursor-pointer " + (index === 0 ? "mt-8" : "mt-2")}
            style={{fontSize: '1.6rem'}}
            onClick={(e) => onRemoveInvoiceRow(e)}
          />
          {!row.article?.value && row.description && row.amount && row.unit?.value && row.sales_account?.value?.number && (
            <i
              className={"fas fa-save text-emerald-500 cursor-pointer text-xl ml-2 " + (index === 0 ? "mt-8" : "mt-2")}
              style={{fontSize: '1.6rem'}}
              onClick={(e) => onAddArticle(e)}
            />
          )}
        </div>
      </div>
    </div>
  )
}
