import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Decimal from 'decimal.js';
import Grid from '@mui/material/Unstable_Grid2'
import dayjs from 'dayjs'
import SharedFields from './SharedFields'
import InvoiceRow from './InvoiceRow'
import ImageUploader from '../../../components/ImageUploader'
import CardStats from "../../../components/Cards/CardStats.js";
import Loader from '../../../components/Loader'
import { validateForm } from '../utils/validate'
import { formatMoneyWithCurrency } from '../../../utils/money'
import {
  termsOfPaymentOptions,
  vatOptions,
  discountOptions,
} from '../../../utils/invoice'
import {
  createLogoAsync,
} from '../invoicesSlice'
import Email from './Email'

export default function Form(props) {
  const dispatch = useDispatch()
  const defaultInvoiceRow1 = {
    id: undefined,
    description: '',
    unit: '',
    quantity: '',
    amount: '',
    discount: { label: '', value: 0.0 },
    article: '',
    sales_account: {}
  }
  const defaultInvoiceRow2 = {
    id: undefined,
    description: '',
    unit: '',
    quantity: '',
    amount: '',
    discount: { label: '', value: 0.0 },
    article: '',
    sales_account: {}
  }
  const defaultInvoiceRow3 = {
    id: undefined,
    description: '',
    unit: '',
    quantity: '',
    amount: '',
    discount: { label: '', value: 0.0 },
    article: '',
    sales_account: {}
  }

  const [selectedCustomer, setSelectedCustomer] = useState('')
  const [customerData, setCustomerData] = useState({})
  const [invoiceDate, setInvoiceDate] = useState(null)
  const [dueDate, setDueDate] = useState(null)
  const [termsOfPayment, setTermsOfPayment] = useState({ label: '30 dagar', value: '30' })
  const [customerOptions, setCustomerOptions] = useState([])
  const [accountOptions, setAccountOptions] = useState([])
  const [articleOptions, setArticleOptions] = useState([])
  const [unitOptions, setUnitOptions] = useState([])
  const [invoiceRows, setInvoiceRows] = useState([defaultInvoiceRow1, defaultInvoiceRow2, defaultInvoiceRow3])
  const [removedInvoiceRows, setRemovedInvoiceRows] = useState([])
  const [vatAmount, setVatAmount] = useState(0)
  const [netAmount, setNetAmount] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [roundOff, setRoundOff] = useState(0)
  const [ourReference, setOurReference] = useState('')
  const [yourReference, setYourReference] = useState('')
  const [invoiceErrors, setInvoiceErrors] = useState([])
  const [invoiceRowErrors, setInvoiceRowErrors] = useState({})
  const [uploadLogoModalOpen, setUploadLogoModalOpen] = useState(false)
  const [emailModalOpen, setEmailModalOpen] = useState(false)
  const [saveOpenAndPrintLoading, setSaveOpenAndPrintLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [currency, setCurrency] = useState({label: 'SEK', value: 'SEK'})
  // TODO: Implement
  // const [otherInvoiceInfoOpen, setOtherInvoiceInfoOpen] = useState(false)

  const resetForm = () => {
    setSelectedCustomer('')
    setCustomerData({})
    setCustomerOptions([])
    setInvoiceRows([defaultInvoiceRow1, defaultInvoiceRow2, defaultInvoiceRow3])
    setYourReference('')
    setOurReference('')
  }

  const setNormalizeInvoiceData = useCallback((invoice, isCopy) => {
    setSelectedCustomer({
      label: invoice.customer.name,
      value: invoice.customer.id
    })
    setCustomerData(invoice.customer)
    setInvoiceDate(dayjs(invoice.invoice_date))
    setYourReference(invoice.your_reference || '')
    setOurReference(invoice.our_reference || '')
    setDueDate(dayjs(invoice.due_date))
    setTermsOfPayment({
      label: invoice.terms_of_payment.description,
      value: invoice.terms_of_payment.code
    })
    const normalizedRows = invoice.invoice_rows.map((row) => normalizeInvoiceRow(row))
    setInvoiceRows(normalizedRows)
  }, [])

  const normalizeInvoiceRow = (row, isCopy) => {
    const vat = vatOptions.find(opt => new Decimal(row.vat).equals(opt.value))
    const discount = discountOptions.find(opt => new Decimal(row.discount).equals(opt.value))
    let article = { label: '', value: '' }
    if (row.article) {
      article = { label: row.article.description, value: row.article.id }
    }

    return {
      id: isCopy ? undefined : row.id,
      description: row.description,
      unit: { label: row.unit.description, value: row.unit.id },
      quantity: row.quantity,
      amount: row.amount,
      vat: vat || { label: '25%', value: 0.25 },
      discount: discount || { label: '', value: 0.0 },
      article: article,
      account: { label: row.account.number, value: row.account.number },
      sales_account: {
        label: row.account.description,
        value: { number: row.account.number, vat: new Decimal(row.vat) }
      }
    }
  }

  useEffect(() => {
    if (props.invoice) {
      setNormalizeInvoiceData(props.invoice, props.fromCopy)
    }
  }, [props.invoice, props.fromCopy, setNormalizeInvoiceData])

  useEffect(() => {
    if (!props.invoice && !invoiceDate) {
      setInvoiceDate(dayjs())
    }

    if (!props.invoice && !dueDate) {
      const currentDate = dayjs()
      const defaultDueDate = currentDate.add(30, "day");
      setDueDate(defaultDueDate)
    }
  }, [props.invoice, invoiceDate, dueDate])

  useEffect(() => {
    if (customerData.id !== selectedCustomer.value) {
      const data = props.customers.find((customer) => {
        return customer.id === selectedCustomer.value
      })

      setCustomerData(data)
      setCurrency({label: data.currency, value: data.currency})
    }
  }, [props.customers, customerData, selectedCustomer])

  useEffect(() => {
    let vatSum = 0
    let netSum = 0

    invoiceRows.forEach((row) => {
      if (row.amount && row.quantity) {
        let amount = new Decimal(row.amount)
        if (row.discount.value > 0 || row.discount > 0) {
          amount = amount - (amount * (row.discount.value || row.discount))
        }

        let vat
        if (row.sales_account?.value?.vat) {
          vat = new Decimal(row.sales_account?.value?.vat)
        } else {
          vat = new Decimal(0)
        }
        const quantity = new Decimal(row.quantity)

        vatSum += amount * vat * quantity
        netSum += amount * quantity
      }
    })

    const total = vatSum + netSum
    const rounded = Math.round(total)

    setVatAmount(vatSum)
    setNetAmount(netSum)
    setTotalAmount(rounded)
    setRoundOff((rounded - total).toFixed(2))
  }, [invoiceRows])

  useEffect(() => {
    const normalized = props.accounts.map((account) => {
      return {
        value: account.number,
        label: account.description,
      }
    })
    setAccountOptions(normalized)
  }, [props.accounts])

  useEffect(() => {
    const normalized = props.customers.map((customer) => {
      return {
        value: customer.id,
        label: customer.name,
      }
    })
    setCustomerOptions(normalized)
  }, [props.customers])

  useEffect(() => {
    const normalized = props.articles.map((article) => {
      return {
        value: article.id,
        label: article.description,
      }
    })
    setArticleOptions(normalized)
  }, [props.articles])

  useEffect(() => {
    const normalized = props.units.map((unit) => {
      return {
        value: unit.id,
        label: unit.description,
      }
    })
    setUnitOptions(normalized)
  }, [props.units])

  const saveAndEmail = async(e, email, ccs) => {
    const response = await onSubmit(e)
    if (response?.meta?.requestStatus === 'fulfilled') {
      await props.sendInvoice(response.payload, email, ccs)
    }
    setEmailModalOpen(false)
  }

  const saveAndOpenForPrint = async(e) => {
    setSaveOpenAndPrintLoading(true)
    const response = await onSubmit(e)
    if (response?.meta?.requestStatus === 'fulfilled') {
      await props.printInvoice(response.payload)
    }
    setSaveOpenAndPrintLoading(false)
  }

  const save = async(e) => {
    setSaveLoading(true)
    await onSubmit(e)
    setSaveLoading(false)
  }

  const onSubmit = async(e) => {
    e.preventDefault()

    const filteredInvoiceRows = invoiceRows.filter((row) => {
      return !Object.values(row).every((v) => {
        if (typeof v === 'object') {
          return !Object.values(row).every(x => x === null || x === '' || x === undefined || x === 0)
        } else {
          return v === null || v === '' || v === undefined || v === 0
        }
      })
    })

    const params = {
      invoice_date: invoiceDate.format('YYYY-MM-DD'),
      due_date: dueDate.format('YYYY-MM-DD'),
      terms_of_payment: termsOfPayment.value,
      currency: currency.value,
      customer_id: selectedCustomer.value,
      our_reference: ourReference,
      your_reference: yourReference,
      template: customerData.invoice_template,
      invoice_rows: filteredInvoiceRows.map((row) => {
        const invoiceRow = {
          description: row.description,
          unit_id: row.unit.value,
          quantity: new Decimal(row.quantity || 0),
          amount: new Decimal(row.amount || 0),
          vat: new Decimal(row.sales_account.value?.vat || 0),
          account_number: parseInt(row.sales_account.value?.number),
          discount: new Decimal(row.discount.value || 0)
        }

        if (row.id) {
          invoiceRow.id = row.id
        }

        if (row.article) {
          invoiceRow.article_id = row.article.value
        }

        return invoiceRow
      })
    }

    const [errors, rowErrors] = validateForm(params, props.activeFinancialYear)
    setInvoiceErrors(errors)
    setInvoiceRowErrors(rowErrors)

    if (errors.length > 0 || Object.keys(rowErrors).length > 0) {
      return
    }

    if (props.creditedInvoice) {
      params.invoice_id = props.invoice.id
      params.credit = true
    }

    let response;

    if (props.invoice && !props.fromCopy && !props.creditedInvoice) {
      removedInvoiceRows.forEach(row => {
         params.invoice_rows.push({id: row.id, removed: true})
      })

      params.id = props.invoice.id
      response = await props.updateInvoice(params)
    } else {
      response = await props.createInvoice(params)
    }

    if (response.meta.requestStatus === 'fulfilled') {
      resetForm()
    } else {
      // TODO: Present error message.
      console.log("error creating invoice")
    }

    return response
  }

  const updateTermsOfPayment = (termsOfPayment) => {
    const date = invoiceDate
    const dueDate = date.add(termsOfPayment.value, "day")
    setDueDate(dueDate)
    setTermsOfPayment(termsOfPayment)
  }

  const updateInvoiceDate = (invoiceDate) => {
    setInvoiceDate(invoiceDate)
    const dueDate = invoiceDate.add(termsOfPayment.value, "day")
    setDueDate(dueDate)
  }

  const addInvoiceRow = (e) => {
    e.preventDefault()

    const rows = [...invoiceRows]
    rows.push(defaultInvoiceRow1)
    setInvoiceRows(rows);
  }

  const removeInvoiceRow = (index) => {
    // TODO: Are you sure prompt if row has been edited (any values)?
    const rows = [...invoiceRows]
    const newRows = rows.filter((_, i) => i !== index);

    const removedRow = rows[index]
    if (removedRow.id) {
      setRemovedInvoiceRows([...removedInvoiceRows, removedRow])
    }

    if (newRows.length === 0) {
      setInvoiceRows([defaultInvoiceRow1])
    } else {
      setInvoiceRows(newRows)
    }
  }

  const updateInvoiceRow = (row, index) => {
    const rows = [...invoiceRows]
    rows[index] = row
    setInvoiceRows(rows)
  }

  const selectArticle = (article, row, index) => {
    const selectedArticle = props.articles.find(art => art.id === parseInt(article.value))
    const unit = props.units.find(u => u.id === selectedArticle.unit_id)
    row.unit = {
      label: unit.description,
      value: unit.id
    }

    const salesAccount = props.activeCompany.sales_accounts.find(acc => selectedArticle.sales_account === acc.account_number)

    row.sales_account = {
      label: salesAccount.description,
      value: {
        number: salesAccount.account_number,
        vat: new Decimal(salesAccount.vat)
      }
    }
    row.amount = new Decimal(selectedArticle.sales_price)
    row.description = selectedArticle.description

    updateInvoiceRow(row, index)
  }

  const createCustomer = async(params) => {
    const response = await props.createCustomer(params)
    setSelectedCustomer({
      label: response.name,
      value: response.id
    })
    setCustomerData(response)
    setCurrency({label: response.currency, value: response.currency})
  }

  const updateCustomer = async(params) => {
    const response = await props.updateCustomer(params)
    setSelectedCustomer({
      label: response.name,
      value: response.id
    })
    setCustomerData(response)
    setCurrency({label: response.currency, value: response.currency})
  }

  const onLogoUpload = async(file) => {
    const formData = new FormData();
    formData.append('logo', file);
    if (props.activeCompany.invoice_settings?.id) {
      formData.append('invoice_setting_id', props.activeCompany.invoice_settings?.id);
    }

    const response = await dispatch(createLogoAsync(formData))

    if (response.type === 'invoices/createLogo/fulfilled') {
      setUploadLogoModalOpen(false)
    }
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
      <div className="flex-auto px-4 lg:px-0 py-0 pt-0">
        <form>
          <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase px-4">
            Skapa faktura
          </h6>
          <SharedFields
            activeCompany={props.activeCompany}
            termsOfPaymentOptions={termsOfPaymentOptions}
            customerData={customerData}
            customerOptions={customerOptions}
            searchCustomers={props.searchCustomers}
            selectCustomer={setSelectedCustomer}
            customer={selectedCustomer}
            updateInvoiceDate={updateInvoiceDate}
            ourReference={ourReference}
            updateOurReference={setOurReference}
            yourReference={yourReference}
            updateYourReference={setYourReference}
            invoiceDate={invoiceDate}
            updateDueDate={setDueDate}
            dueDate={dueDate}
            updateTermsOfPayment={updateTermsOfPayment}
            termsOfPayment={termsOfPayment}
            createCustomer={createCustomer}
            updateCustomer={updateCustomer}
            customerFormOpen={props.customerFormOpen}
            toggleCustomerForm={props.toggleCustomerForm}
            invoiceErrors={invoiceErrors}
            currency={currency}
            updateCurrency={setCurrency}
          />

          <hr className="mt-4 border-b-1 border-slate-300" />

          <div className="mt-4">
            {invoiceRows.map((row, i) => (
              <InvoiceRow
                key={i}
                index={i}
                row={row}
                addInvoiceRow={addInvoiceRow}
                removeInvoiceRow={removeInvoiceRow}
                updateInvoiceRow={updateInvoiceRow}
                discountOptions={discountOptions}
                unitOptions={unitOptions}
                vatOptions={vatOptions}
                accountOptions={accountOptions}
                searchAccounts={props.searchAccounts}
                articleOptions={articleOptions}
                selectArticle={selectArticle}
                createArticle={props.createArticle}
                errors={invoiceRowErrors[i] || []}
                salesAccounts={props.activeCompany.sales_accounts}
              />
            ))}
          </div>

          <div className="w-full lg:w-3/12 px-5 py-1 pb-0">
            <div className="relative w-full mb-3">
              <button
                className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={(e) => addInvoiceRow(e)}
              >
                Lägg till fakturarad
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* TODO: Implement

      <hr className="mt-4 border-b-1 border-slate-300" />

      <h6 className="text-slate-400 text-sm mt-4 mb-1 font-bold uppercase px-4">
        Övrig fakturainformation
        {otherInvoiceInfoOpen ?
          <i className="fas fa-chevron-up ml-2 cursor-pointer" onClick={() => setOtherInvoiceInfoOpen(false)}></i>
          :
          <i className="fas fa-chevron-down ml-2 cursor-pointer" onClick={() => setOtherInvoiceInfoOpen(true)}></i>
        }
      </h6>

      {otherInvoiceInfoOpen &&
        <div className="flex flex-wrap mt-2">
          <div className="w-full lg:w-6/12 px-2 pl-4">
            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-slate-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Fakturatext
              </label>
              <textarea
                type="text"
                className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                rows="2"
              ></textarea>
            </div>
          </div>
        </div>
      }*/}


      <hr className="mt-2 border-b-1 border-slate-300" />

      <div className="flex flex-wrap mt-4 mb-2">
        <div className="w-full lg:w-3/12 px-2 pl-4">
          <div className="relative w-full mb-3">
            <CardStats
              statSubtitle="Netto"
              statTitle={formatMoneyWithCurrency(netAmount, currency.label)}
              statPercentColor="text-emerald-500"
              statIconName="fas fa-money-check-alt"
              statIconColor="bg-orange-500"
            />
          </div>
        </div>
        <div className="w-full lg:w-3/12 px-2 pl-4">
          <div className="relative w-full mb-3">
            <CardStats
              statSubtitle="Moms"
              statTitle={formatMoneyWithCurrency(vatAmount, currency.label)}
              statPercentColor="text-emerald-500"
              statIconName="fas fa-university"
              statIconColor="bg-orange-500"
            />
          </div>
        </div>
        {roundOff !== 0 &&
          <div className="w-full lg:w-3/12 px-2 pl-4">
            <div className="relative w-full mb-3">
              <CardStats
                statSubtitle="Öresavrundning"
                statTitle={formatMoneyWithCurrency(roundOff, currency.label)}
                statPercentColor="text-emerald-500"
                statIconName="fas fa-coins"
                statIconColor="bg-orange-500"
              />
            </div>
          </div>
        }
        <div className="w-full lg:w-3/12 px-2 pl-4">
          <div className="relative w-full mb-3">
            <CardStats
              statSubtitle="Totalt"
              statTitle={formatMoneyWithCurrency(totalAmount, currency.label)}
              statPercentColor="text-emerald-500"
              statIconName="fas fa-money-check-alt"
              statIconColor="bg-orange-500"
            />
          </div>
        </div>
      </div>

      {invoiceErrors.includes('incorrect_financial_year') &&
        <Grid item xs={12}>
          <p className="pl-4">Fakturadatum ligger utanför aktuellt räkenskapsår. Vänligen välj korrekt räkenskapsår.</p>
        </Grid>
      }

      {invoiceErrors.includes('no_invoice_rows') &&
        <Grid item xs={12}>
          <p className="pl-4">Fakturan innehåller inte några fakturarader.</p>
        </Grid>
      }

      <div className="flex flex-wrap mt-2 mb-2">
        <div className="w-full lg:w-3/12 px-2 pl-4">
          <div className="relative w-full mb-3">
            <button
              style={{height: '48px'}}
              onClick={(e) => save(e)}
              className="w-full bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              { saveLoading ?
                <Loader size={28} thickness={4} style={{color: '#fff'}}/>
                :
                "Spara"
              }
            </button>
          </div>
        </div>
   {/* TODO: Implement    <div className="w-full lg:w-3/12 px-2 pl-4">
          <div className="relative w-full mb-3">
            <button
              style={{height: '48px'}}
              className="w-full bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              Förhandsgranska
            </button>
          </div>
        </div>*/}
        <div className="w-full lg:w-3/12 px-2 pl-4">
          <div className="relative w-full mb-3">
            <button
              style={{height: '48px'}}
              onClick={(e) => saveAndOpenForPrint(e)}
              className="w-full bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              { saveOpenAndPrintLoading ?
                <Loader size={28} thickness={4} style={{color: '#fff'}}/>
                :
                "Spara & Öppna för utskrift"
              }
            </button>
          </div>
        </div>
        <div className="w-full lg:w-3/12 px-2 pl-4">
          <div className="relative w-full mb-3">
            <button
              style={{height: '48px'}}
              onClick={() => setEmailModalOpen(true)}
              className="w-full bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs p-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              Spara & Skicka med E-post
            </button>
          </div>
        </div>
      </div>

      <ImageUploader
        title="Ladda upp logo"
        onClose={() => setUploadLogoModalOpen(false)}
        onUpload={onLogoUpload}
        open={uploadLogoModalOpen}
      />
      <Email
        close={() => setEmailModalOpen(false)}
        invoiceEmail={customerData?.email}
        onSave={(e, email, ccs) => saveAndEmail(e, email, ccs)}
        open={emailModalOpen}
      />
    </div>
  )
}
