export const invoiceSelectLarge = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
      marginLeft: 0,
      minWidth: '300px',
      border: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      fontSize: '13px',
      cursor: 'pointer',
      height: '35px',
      padding: '5px 0 0 5px',
      backgroundColor: state.isSelected ? "rgb(14 165 233)" : "#fff",
      '&:hover': {
        backgroundColor: 'rgb(14 165 233)',
        color: '#fff',
      }
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: '13px',
      border: error ? '1px solid rgb(239 68 68)' : 0,
      boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 0.1)',
      cursor: 'pointer',
      textAlign: 'left',
      height: '44px',
      '&:hover': {
        border: error ? '1px solid rgb(239 68 68)' : 0,
        boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 0.1)',
      }
    })
  }
}

export const invoiceSelectSmall = (error) => {
  return {
    menu: (provided, state) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      zIndex: 999,
    }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left',
      fontSize: '13px',
      cursor: 'pointer',
      backgroundColor: state.isSelected ? "rgb(14 165 233)" : "#fff",
      '&:hover': {
        backgroundColor: 'rgb(14 165 233)',
        color: '#fff',
      }
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: '13px',
      width: '100%',
      cursor: 'pointer',
      border: error ? '1px solid rgb(239 68 68)' : 0,
      boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 0.1)',
      height: '44px',
      '&:hover': {
        border: error ? '1px solid rgb(239 68 68)' : 0,
        boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 0.1)',
      }
    }),
    dropdownIndicator: (provided) => ({
    ...provided,
      width: '25px',
      paddingTop: '0',
      paddingBottom: '0',
    }),
  }
}
