import React from "react";
import PropTypes from "prop-types";

export default function CardStats({
  statSubtitle,
  statTitle,
  statArrow,
  statPercent,
  statPercentColor,
  statDescription,
  statIconName,
  statIconColor,
}) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5 className="text-slate-400 uppercase font-bold text-xs">
                {statSubtitle}
              </h5>
              { statTitle &&
                <span className="font-semibold text-xl text-slate-700">
                  {statTitle}
                </span>
              }
            </div>
            <div className="relative w-auto pl-4 flex-initial">
              <div
                className={
                  "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full " +
                  statIconColor
                }
              >
                <i className={statIconName}></i>
              </div>
            </div>
          </div>
          {statPercent !== undefined && statDescription !== undefined &&
            <p className="text-sm text-slate-400 mt-4">
              {statPercent &&
                <span className={statPercentColor + " mr-2"}>
                  <i
                    className={
                      statArrow === "up"
                        ? "fas fa-arrow-up"
                        : statArrow === "down"
                        ? "fas fa-arrow-down"
                        : ""
                    }
                  ></i>{" "}
                  {statPercent}%
                </span>
              }
              {statDescription &&
                <span className="whitespace-nowrap">{statDescription}</span>
              }
            </p>
          }
        </div>
      </div>
    </>
  );
}

CardStats.defaultProps = {
  statSubtitle: "Traffic",
  statTitle: undefined,
  statArrow: undefined,
  statPercent: undefined,
  statPercentColor: "text-emerald-500",
  statDescription: undefined,
  statIconName: "far fa-chart-bar",
  statIconColor: "bg-red-500",
};

CardStats.propTypes = {
  statSubtitle: PropTypes.string,
  statTitle: PropTypes.string,
  statArrow: PropTypes.oneOf(["", "up", "down"]),
  statPercent: PropTypes.string,
  // can be any of the text color utilities
  // from tailwindcss
  statPercentColor: PropTypes.string,
  statDescription: PropTypes.string,
  statIconName: PropTypes.string,
  // can be any of the background color utilities
  // from tailwindcss
  statIconColor: PropTypes.string,
};
